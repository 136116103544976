import Typography from "typography"
// import theme from "typography-theme-wordpress-2016"
import theme from "typography-theme-github"
// import theme from "typography-theme-legible"
// import theme from "typography-theme-twin-peaks"
// import theme from "typography-theme-wordpress-kubrick"
// import theme from "typography-theme-us-web-design-standards"
// import theme from 'typography-theme-sutro'
// import theme from 'typography-theme-stern-grove'
// import theme from 'typography-theme-moraga'
// import theme from 'typography-theme-fairy-gates'

theme.overrideThemeStyles = () => {
  return {
    h1: {
      borderBottom: ``,
    },
    h2: {
      borderBottom: ``,
    },
    a: {
      color: `var(--textLink)`,
    },
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    hr: {
      background: 'var(--hr)',
    },
  }
}

delete theme.googleFonts

const typography = new Typography(theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
