import React from "react"
import { Link, useStaticQuery } from "gatsby"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import Toggle from "react-toggle"
import "react-toggle/style.css"

import { rhythm, scale } from "../utils/typography"
import ToggleEmoji from "./toggle"

const Layout = ({ location, title, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              twitter
              scribd
              github
            }
          }
        }
      }
    `
  )
  const social = site.siteMetadata.social

  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
        <h1
          style={{
            ...scale(1.5),
            // marginBottom: rhythm(1.5),
            marginBottom: 0,
            marginRight: `8px`,
            fontFamily: `Cabin, sans-serif`,
            paddingBottom: 0,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Cabin, sans-serif`,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(26),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header style={{ height: `3.25rem`, display: `flex`, alignItems: `center`, marginBottom: rhythm(1), justifyContent: `space-between` }}>
        {header}
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <ToggleEmoji open={theme === "dark"} size="small" customEmojiOpen="🦉" customEmojiClose="🦉" onClick={e => toggleTheme(theme === "dark" ? "light" : "dark")} />
              // <Toggle
              //   checked={theme === "dark"}
              //   icons={{
              //     checked: `🦉`,
              //     unchecked: null,
              //   }}
              //   onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
              // />
          )}
        </ThemeToggler>
      </header>
      <main>{children}</main>
      <footer style={{ marginTop: rhythm(2.5) }}>
        <a style={{ float: "right" }} href={`https://sben.dev/rss.xml`}>
          feed
        </a>
        <a href={`https://github.com/${social.github}`}>github</a> -{" "}
        <a href={`https://scribd.com/user/${social.scribd}`}>scribd</a> -{" "}
        <a href={`https://twitter.com/${social.twitter}`}>twitter</a>
      </footer>
    </div>
  )
}

export default Layout
