import React from 'react'
import styled from 'styled-components'
import { rhythm } from "../utils/typography"

const sizes = {
  default: {
    width: rhythm(1.5),
    height: rhythm(1.5),
    fontSize: rhythm(0.9),
  },
}

const emojiList = {
  book: {
    open: '📖',
    close: '📘'
  },
  glasses: {
    open: '😍',
    close: '😎'
  },
  laugh: {
    open: '🤣',
    close: '😂'
  },
  horse: {
    open: '🦄',
    close: '🐴'
  },
  skull: {
    open: '☠',
    close: '💀'
  },

  hand: {
    open: '👍',
    close: '👎'
  },
  shoe: {
    open: '👠',
    close: '👡'
  },
  sound: {
    open: '🔊',
    close: '🔈'
  },
  mailbox: {
    open: '📭',
    close: '📫'
  },
  moon: {
    open: '🌕',
    close: '🌑'
  },
  fire: {
    open: '🔥',
    close: '💧'
  },
  arrow: {
    open: '⬇',
    close: '⬆'
  }
}

const Button = styled.button`
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: 125ms;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${sizes.default.width};
  height: ${sizes.default.height};
  font-size: ${sizes.default.fontSize};
  background-color: var(--toggleBg);
  box-shadow: ${props =>
    props.noShadow
      ? 'none'
      : 'var(--shadow)'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '50%')};
  border: ${props => (props.border ? props.border : 'none')};

  &:hover {
    box-shadow: var(--shadowHover);
  }
`

export default class ToggleEmoji extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  render() {
    const { open, size, emojiSet, customEmojiOpen, customEmojiClose, onClick } = this.props

    let emojiOpen = emojiList.book.open
    let emojiClose = emojiList.book.close

    // Set emoji based on emojiSet
    if (Object.keys(emojiList).includes(emojiSet)) {
      emojiOpen = emojiList[emojiSet].open
      emojiClose = emojiList[emojiSet].close
    }

    // Set emoji based on custom emoji
    if (customEmojiOpen && customEmojiClose) {
      emojiOpen = customEmojiOpen
      emojiClose = customEmojiClose
    }

    return (
      <Button
        {...this.props}
        className={size}
        onClick={onClick}
      >
        {open ? emojiOpen : emojiClose}
      </Button>
    )
  }
}